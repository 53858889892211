const PriceIcon = ({ color = '#2563EB' }) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.91507 10.9565C2.81108 9.7356 3.27245 8.53503 4.16702 7.69867L6.79182 5.24466C8.14186 3.98248 10.1823 3.81097 11.7266 4.82986L14.7291 6.81084C15.7525 7.48599 16.4103 8.59248 16.5143 9.81336L17.4451 20.7408C17.6326 22.942 16 24.8764 13.7986 25.0615L8.17123 25.5345C5.96986 25.7195 4.03331 24.0851 3.84582 21.884L2.91507 10.9565Z"
      fill={color}
      fillOpacity={0.2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5101 9.72963L18.4409 20.6571C18.6752 23.4086 16.6345 25.8266 13.8828 26.0579L8.25543 26.5309C5.50371 26.7622 3.08301 24.7192 2.84866 21.9678L1.9179 11.0403C1.78792 9.51418 2.36463 8.01346 3.48284 6.96801L6.10765 4.51401C7.79519 2.93627 10.3457 2.72188 12.2761 3.9955L15.2787 5.97648C16.5578 6.82041 17.3801 8.20353 17.5101 9.72963ZM4.16634 7.6987C3.27177 8.53506 2.8104 9.73564 2.91439 10.9565L3.84514 21.884C4.03263 24.0852 5.96918 25.7196 8.17056 25.5345L13.7979 25.0615C15.9993 24.8765 17.6319 22.942 17.4444 20.7409L16.5136 9.81339C16.4097 8.59251 15.7518 7.48602 14.7285 6.81087L11.7259 4.82989C10.1816 3.811 8.14118 3.98251 6.79114 5.2447L4.16634 7.6987Z"
      fill={color}
    />
    <path
      d="M9.33014 15.144C8.77765 14.0523 8.75653 12.7704 9.27282 11.6646L10.7867 8.42221C11.5685 6.74762 13.4047 5.83056 15.224 6.206L18.7467 6.93295C19.9481 7.18087 20.9712 7.96231 21.5237 9.05402L26.4871 18.8616C27.4847 20.8327 26.6912 23.2307 24.7148 24.2178L19.6783 26.7333C17.7019 27.7204 15.2911 26.9227 14.2936 24.9516L9.33014 15.144Z"
      fill={color}
      fillOpacity={0.2}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.4188 8.60732L27.3822 18.4149C28.6292 20.8788 27.6373 23.8763 25.1669 25.1102L20.1303 27.6256C17.6599 28.8595 14.6464 27.8624 13.3994 25.3985L8.43599 15.5909C7.74538 14.2263 7.71898 12.624 8.36434 11.2417L9.8782 7.99929C10.8555 5.90607 13.1507 4.75973 15.4249 5.22904L18.9476 5.95598C20.4493 6.26588 21.7282 7.24269 22.4188 8.60732ZM9.2733 11.6647C8.75701 12.7705 8.77813 14.0524 9.33062 15.1441L14.2941 24.9517C15.2916 26.9228 17.7024 27.7205 19.6788 26.7334L24.7153 24.2179C26.6917 23.2309 27.4852 20.8328 26.4876 18.8617L21.5242 9.05414C20.9717 7.96243 19.9486 7.18098 18.7472 6.93307L15.2245 6.20612C13.4052 5.83068 11.569 6.74774 10.7872 8.42232L9.2733 11.6647Z"
      fill={color}
    />
    <path
      d="M15.3513 9.38667C15.6516 9.98004 15.4108 10.7029 14.8135 11.0012C14.2162 11.2996 13.4886 11.0604 13.1883 10.467C12.888 9.87361 13.1287 9.15074 13.7261 8.85242C14.3234 8.5541 15.051 8.79329 15.3513 9.38667Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.362 10.109C14.4685 10.0558 14.5059 9.93077 14.4567 9.83348C14.4075 9.73619 14.2842 9.69145 14.1776 9.74467C14.0711 9.79788 14.0337 9.92288 14.0829 10.0202C14.1321 10.1175 14.2554 10.1622 14.362 10.109ZM14.8135 11.0012C15.4108 10.7029 15.6516 9.98004 15.3513 9.38667C15.051 8.79329 14.3234 8.5541 13.7261 8.85242C13.1287 9.15074 12.888 9.87361 13.1883 10.467C13.4886 11.0604 14.2162 11.2996 14.8135 11.0012Z"
      fill={color}
    />
  </svg>
)

export default PriceIcon
