const RatingReviewIcon = ({ color = '#2563EB' }) => (
  <svg
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3852 12.1855C13.2514 12.5975 12.8674 12.8765 12.4342 12.8765H7.18335C6.89273 12.8765 6.7719 13.2484 7.00702 13.4192L11.255 16.5055C11.6055 16.7602 11.7522 17.2116 11.6183 17.6236L9.9957 22.6174C9.90589 22.8938 10.2222 23.1236 10.4574 22.9528L14.7054 19.8665C15.0558 19.6118 15.5304 19.6118 15.8809 19.8665L20.1289 22.9528C20.364 23.1236 20.6804 22.8938 20.5906 22.6174L18.968 17.6236C18.8341 17.2116 18.9808 16.7602 19.3313 16.5055L23.5793 13.4192C23.8144 13.2484 23.6935 12.8765 23.4029 12.8765H18.1521C17.7189 12.8765 17.3349 12.5975 17.2011 12.1855L15.5785 7.19167C15.4887 6.91528 15.0976 6.91528 15.0078 7.19167L13.3852 12.1855ZM16.5295 6.88265L18.1521 11.8765L23.4029 11.8765C24.6623 11.8765 25.1859 13.488 24.1671 14.2282L19.919 17.3146L21.5416 22.3084C21.9308 23.5061 20.56 24.5021 19.5411 23.7618L15.2931 20.6755L11.0451 23.7618C10.0263 24.5021 8.65548 23.5061 9.04464 22.3084L10.6672 17.3146L6.41923 14.2282C5.40041 13.488 5.924 11.8765 7.18335 11.8765H12.4342L14.0568 6.88265C14.4459 5.68496 16.1404 5.68494 16.5295 6.88265Z"
      fill={color}
    />
  </svg>
)

export default RatingReviewIcon
