const VisibilityIcon = ({ color = '#2563EB' }) => (
  <svg
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="18.2812"
      cy="15"
      r="7.99352"
      transform="rotate(-45 18.2812 15)"
      fill={color}
      fillOpacity={0.2}
      stroke={color}
    />
    <path
      d="M27.7776 25.202C27.9729 25.3972 28.2894 25.3972 28.4847 25.202C28.68 25.0067 28.68 24.6901 28.4847 24.4949L27.7776 25.202ZM23.3086 20.7329L27.7776 25.202L28.4847 24.4949L24.0157 20.0258L23.3086 20.7329Z"
      fill={color}
    />
    <path
      d="M5.3025 9.87322L8.50441 21.7901C8.61999 22.2203 9.19798 22.296 9.42045 21.9101L12.0118 17.4151C12.0705 17.3134 12.1631 17.2355 12.2734 17.1952L17.1467 15.4143C17.5651 15.2614 17.5897 14.679 17.1858 14.4913L5.99613 9.29007C5.61111 9.1111 5.19233 9.46318 5.3025 9.87322Z"
      stroke={color}
    />
  </svg>
)

export default VisibilityIcon
