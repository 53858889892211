const LinkedinIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 66 66"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M51.6283 9.07764C53.0648 9.07764 54.4426 9.64832 55.4584 10.6641C56.4742 11.68 57.0449 13.0577 57.0449 14.4943V52.411C57.0449 53.8476 56.4742 55.2253 55.4584 56.2411C54.4426 57.257 53.0648 57.8276 51.6283 57.8276H13.7116C12.275 57.8276 10.8972 57.257 9.88143 56.2411C8.8656 55.2253 8.29492 53.8476 8.29492 52.411V14.4943C8.29492 13.0577 8.8656 11.68 9.88143 10.6641C10.8972 9.64832 12.275 9.07764 13.7116 9.07764H51.6283ZM50.2741 51.0568V36.7026C50.2741 34.361 49.3439 32.1153 47.6881 30.4595C46.0323 28.8037 43.7866 27.8735 41.4449 27.8735C39.1428 27.8735 36.4616 29.2818 35.1616 31.3943V28.3881H27.6053V51.0568H35.1616V37.7047C35.1616 35.6193 36.8408 33.9131 38.9262 33.9131C39.9318 33.9131 40.8962 34.3125 41.6073 35.0236C42.3184 35.7347 42.7178 36.6991 42.7178 37.7047V51.0568H50.2741ZM18.8033 24.136C20.01 24.136 21.1673 23.6566 22.0206 22.8033C22.8739 21.95 23.3533 20.7927 23.3533 19.586C23.3533 17.0672 21.322 15.0089 18.8033 15.0089C17.5893 15.0089 16.4251 15.4911 15.5668 16.3495C14.7084 17.2079 14.2262 18.3721 14.2262 19.586C14.2262 22.1047 16.2845 24.136 18.8033 24.136ZM22.5678 51.0568V28.3881H15.0658V51.0568H22.5678Z"
      fill="currentColor"
    />
  </svg>
)

export default LinkedinIcon
