import clsx from 'clsx'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ReactNode, useEffect, useState } from 'react'

import ArrowDownIcon from '@/components/icons/arrow-down'
import CloseIcon from '@/components/icons/close'
import InstagramIcon from '@/components/icons/instagram'
import LinkedinIcon from '@/components/icons/linkedin'
import MenuIcon from '@/components/icons/menu'
import SOLUTIONS from '@/modules/landing-page/constants/solutions'

import Logo from './logo'

export function toTitleCase(str: string, separator = /[\s-_]+/): string {
  return str
    .split(separator)
    .map(
      word =>
        word.charAt(0).toLocaleUpperCase() + word.slice(1).toLocaleLowerCase()
    )
    .join(' ')
}

const SolutionsDropdown = () => {
  return (
    <div className="mt-2 grid w-max min-w-0 grid-cols-2 grid-rows-3 gap-4 rounded-lg border border-zinc-50 bg-white p-4 shadow-lg">
      {SOLUTIONS.map((solution, index) => (
        <Link
          key={index}
          href={solution.url}
          style={{
            color: solution.color,
          }}
          className="rounded-lg bg-opacity-50 p-2 duration-200 hover:bg-zinc-50"
          title={solution.title}
        >
          <div className="flex min-w-0 gap-2">
            <div
              style={{
                backgroundColor: `${solution.color}1A`,
              }}
              className="flex h-12 w-12 items-center justify-center rounded-full"
            >
              <solution.Icon color={solution.color} />
            </div>

            <div className="flex min-w-0 max-w-xs flex-col">
              <div className="text-lg font-medium text-zinc-600">
                {solution.title}
              </div>
              <p
                className="flex-1 flex-wrap gap-2 truncate text-sm text-zinc-400"
                title={solution.tags.map(tag => toTitleCase(tag)).join(' • ')}
              >
                {solution.tags.map(tag => toTitleCase(tag)).join(' • ')}
              </p>
            </div>
          </div>
        </Link>
      ))}
    </div>
  )
}

const MenuItem = ({
  href,
  label,
  active,
  dropdown,
}: {
  href: string
  label: string
  active?: boolean
  dropdown?: ReactNode
}) => {
  return (
    <li className="group relative flex flex-shrink-0">
      <Link
        href={href}
        className={clsx(
          'inline-flex items-center justify-center gap-2 px-4 py-2.5 text-base font-medium duration-200 hover:text-brand-600',
          dropdown && 'rounded-lg hover:bg-zinc-50',
          active ? 'text-brand-600' : 'text-zinc-500'
        )}
      >
        {label}
        {dropdown && (
          <ArrowDownIcon className="duration-300 group-hover:rotate-180" />
        )}
      </Link>

      {dropdown && (
        <div className="pointer-events-none absolute left-0 top-11 translate-y-4 opacity-0 duration-300 group-hover:pointer-events-auto group-hover:translate-y-0 group-hover:opacity-100">
          {dropdown}
        </div>
      )}
    </li>
  )
}

const Header = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const router = useRouter()

  useEffect(() => {
    setMobileMenuOpen(false)
  }, [router.asPath])

  return (
    <header className="fixed left-0 top-0 z-50 w-full border-b border-white bg-white bg-opacity-70 backdrop-blur">
      <div className="mx-auto flex w-full max-w-container gap-16 px-4 py-4 sm:px-8 md:px-12">
        <div className="flex items-center justify-center gap-x-12">
          <Link
            id="logo"
            href="/"
            className="flex text-xl text-[#112A55] md:text-[28px]"
            title="Go to homepage"
          >
            <Logo />
          </Link>
          <ul className="hidden list-none items-center md:flex">
            <MenuItem
              href="/solutions"
              label="Solutions"
              active={router.asPath.startsWith('/solutions')}
              dropdown={<SolutionsDropdown />}
            />
            <MenuItem
              href="/company"
              label="Company"
              active={router.asPath.startsWith('/company')}
            />
            <MenuItem
              href="/team"
              label="Team"
              active={router.asPath.startsWith('/team')}
            />
            <MenuItem
              href="/blog"
              label="Blog"
              active={router.asPath.startsWith('/blog')}
            />
          </ul>
        </div>

        <nav id="nav-menu" className="ml-auto flex gap-8">
          <div className="flex items-center">
            <button
              className={clsx(
                'menu-icon flex transform-gpu items-center p-1 text-3xl duration-200 md:hidden',
                mobileMenuOpen && 'rotate-180'
              )}
              onClick={() => {
                setMobileMenuOpen(!mobileMenuOpen)
              }}
            >
              {mobileMenuOpen ? <CloseIcon /> : <MenuIcon />}
            </button>

            <a
              id="login-button"
              href="https://app.themindsite.com/login"
              target="_blank"
              rel="noopener noreferrer"
              className="hidden rounded-full px-8 py-2 text-base text-brand-700 md:flex"
            >
              Login
            </a>
            <Link
              id="demo-request-button"
              href="/contact-us"
              className="hidden rounded-full bg-brand-700  px-8 py-2 text-base text-slate-50 duration-200 hover:bg-brand-500 focus:bg-brand-500 active:bg-brand-700 md:flex"
            >
              Request a Demo
            </Link>
          </div>
        </nav>

        <div
          id="mobile-menu"
          className={clsx(
            'fixed bottom-0 left-0 top-16 z-50 block h-[calc(100dvh_-_4rem)] w-full transform bg-white transition-transform duration-500 ease-in-out md:hidden',
            mobileMenuOpen ? 'translate-x-0' : 'translate-x-full'
          )}
        >
          <div className="flex h-full min-h-0 flex-col gap-2 overflow-y-auto overscroll-contain p-5 [&_ul]:ml-0">
            <ul className="flex list-none flex-col gap-8 ">
              <li className="flex flex-col items-start justify-start gap-4">
                <Link
                  href="/solutions"
                  className="text-md font-medium text-zinc-400"
                >
                  Solutions
                </Link>

                <ul className="flex list-none flex-col gap-4 ">
                  {SOLUTIONS.map((solution, index) => (
                    <li key={index}>
                      <Link
                        href={solution.url}
                        className="flex gap-2 text-xl font-medium text-zinc-700 duration-200 hover:bg-zinc-50"
                        title={solution.title}
                      >
                        <solution.Icon color={solution.color} />

                        {solution.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>

              <li className="flex flex-col items-start justify-start gap-4">
                <Link
                  href="/blog"
                  className="text-md font-medium text-zinc-400"
                >
                  Blog
                </Link>
              </li>

              <li className="flex flex-col items-start justify-start gap-4">
                <Link
                  href="/company"
                  className="text-md font-medium text-zinc-400"
                >
                  Company
                </Link>

                <ul className="flex list-none flex-col gap-4 ">
                  <li>
                    <Link
                      href="/company"
                      className="text-xl font-medium text-zinc-700"
                    >
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/team"
                      className="text-xl font-medium text-zinc-700"
                    >
                      Team
                    </Link>
                  </li>
                  <li>
                    <Link
                      href="/contact-us"
                      className="text-xl font-medium text-zinc-700"
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link
                  id="header-cta"
                  href="/contact-us"
                  className="flex items-center justify-center rounded-full bg-brand-600 px-4 py-2 text-center text-base text-slate-50"
                >
                  Request a Demo
                </Link>
              </li>

              <li className="flex flex-col items-start justify-start gap-4">
                <p className="text-md font-medium text-zinc-400">
                  Social Media
                </p>

                <ul className="flex list-none gap-2 ">
                  <li>
                    <a
                      href="https://www.linkedin.com/company/themindsite"
                      className="text-4xl font-medium text-zinc-700"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="LinkedIn"
                    >
                      <LinkedinIcon />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/themindsite"
                      className="text-4xl font-medium text-zinc-700"
                      target="_blank"
                      rel="noopener noreferrer"
                      title="LinkedIn"
                    >
                      <InstagramIcon />
                    </a>
                  </li>
                </ul>
              </li>
            </ul>

            <p className="text-sm text-zinc-400">
              Mindsite © {new Date().getFullYear()}. All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
