import '../styles/main.scss'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import { DefaultSeo, OrganizationJsonLd, SocialProfileJsonLd } from 'next-seo'
import { consent, GoogleAnalytics } from 'nextjs-google-analytics'

import AppLayout from '@/components/app-layout'

const CookiePopUp = dynamic(() => import('@/components/cookie-popup'), {
  ssr: false,
})

const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL

function MyApp({ Component, pageProps }: AppProps) {
  const queryClient = new QueryClient()

  return (
    <AppLayout>
      <DefaultSeo
        languageAlternates={[
          {
            hrefLang: 'en',
            href: BASE_URL as string,
          },
        ]}
        defaultTitle="Mindsite"
        titleTemplate="%s | Mindsite"
        description="Gain a competitive edge with Mindsite’s powerful e-commerce data analytics. Our comprehensive data analytics solutions provide actionable insights to drive your business growth and outperform your competitors."
        robotsProps={{
          nosnippet: false,
          notranslate: false,
          noimageindex: false,
          noarchive: false,
          maxSnippet: -1,
          maxImagePreview: 'large',
          maxVideoPreview: -1,
        }}
        themeColor="#2563EB"
        openGraph={{
          type: 'website',
          locale: 'en_IE',
          url: BASE_URL as string,
          siteName: 'Mindsite',
          images: [
            {
              url: `${BASE_URL}/og-image.png`,
              width: 1200,
              height: 675,
              alt: 'Mindsite',
            },
          ],
        }}
        twitter={{
          handle: '@handle',
          site: '@site',
          cardType: 'summary_large_image',
        }}
      />
      <OrganizationJsonLd
        type="Corporation"
        name="Mindsite"
        url={BASE_URL as string}
        legalName="Mindsite Teknoloji ve Danışmanlık A.Ş"
        logo={`${BASE_URL}/logo.png`}
        address={{
          streetAddress: 'Yeşilce Mah. Göktürk cad, Çeşni Sok. No:4 Kat:3',
          addressLocality: 'Kağıthane',
          addressRegion: 'İstanbul',
          postalCode: '34418',
          addressCountry: 'TR',
        }}
        contactPoint={[
          {
            telephone: '+44 7391 304322',
            contactType: 'customer service',
            availableLanguage: ['English', 'Turkish'],
          },
        ]}
      />

      <SocialProfileJsonLd
        type="Organization"
        name="Mindsite"
        url={BASE_URL as string}
        sameAs={[
          'https://www.instagram.com/themindsite',
          'https://www.linkedin.com/company/themindsite',
        ]}
      />

      <GoogleAnalytics trackPageViews />
      <QueryClientProvider client={queryClient}>
        <Component {...pageProps} />
      </QueryClientProvider>
      <CookiePopUp consent={consent} />
    </AppLayout>
  )
}

export default MyApp
