import ContentIcon from '@/components/icons/content'
import ERetailMediaIcon from '@/components/icons/e-retail-media'
import MultiLocationIcon from '@/components/icons/multi-location'
import PriceIcon from '@/components/icons/price'
import RatingReviewIcon from '@/components/icons/rating-review'
import VisibilityIcon from '@/components/icons/visibility'

export type Solution = {
  key: string
  title: string
  url: string
  tags: string[]
  tabs: string[]
  description: string[]
  videoUrl: string
  videoPosterUrl: string
  color: string
  Icon: typeof ContentIcon
}

const SOLUTIONS: Solution[] = [
  {
    key: 'price',
    title: 'Price',
    url: '/solutions/price',
    tabs: [],
    tags: [
      'AVAILABILITY',
      'BUY BOX',
      'OWNING',
      'DISCOUNT',
      'DELIVERY',
      'PROMOTION',
    ],
    description: [
      'Monitor real-time prices and gain insights into discounted rates using our e-commerce analytics. Assess product availability and take data-driven actions to win the buy box. Use our price trend feature to quickly respond to competitor discounts.',
    ],
    Icon: PriceIcon,
    videoUrl: '/media/solutions/price.mp4',
    videoPosterUrl: '/media/solutions/price-poster.png',
    color: '#EF8C30',
  },
  {
    key: 'visibility',
    title: 'Visibility',
    url: '/solutions/visibility',
    tabs: ['Search by keyword', 'Search by product', 'Category'],
    tags: ['SEARCH', 'CATEGORY PAGE', 'ORGANIC / SPONSORED'],
    description: [
      'Boost your organic traffic and improve your brand’s online presence. Evaluate your search and category rankings hourly to maintain a competitive edge. Stay visible among the top brands through regular monitoring.',
    ],
    Icon: VisibilityIcon,
    videoUrl: '/media/solutions/visibility.mp4',
    videoPosterUrl: '/media/solutions/visibility-poster.png',
    color: '#8950FC',
  },
  {
    key: 'content',
    title: 'Content',
    url: '/solutions/content',
    tabs: [],
    tags: ['AVERAGE RATING', 'SHOPPER REVIEWS'],
    description: [
      'Optimize product titles, descriptions, and images for the digital shelf. Validate content compliance and build consumer trust. Ensure your content includes vital keywords to improve retailer rankings.',
    ],
    Icon: ContentIcon,
    videoUrl: '/media/solutions/content.mp4',
    videoPosterUrl: '/media/solutions/content-poster.png',
    color: '#2563EB',
  },
  {
    key: 'rating-review',
    title: 'Rating & Review',
    url: '/solutions/rating-review',
    tabs: [],
    tags: ['AVERAGE RATING', 'POPULAR REVIEWS'],
    description: [
      'Collect and analyze product ratings and reviews from various retailers. Gain insights into the number of products reviewed and rated. Utilize this feedback to enhance your offerings and ensure customer satisfaction.',
    ],
    Icon: RatingReviewIcon,
    videoUrl: '/media/solutions/rating-review.mp4',
    videoPosterUrl: '/media/solutions/rating-review-poster.png',
    color: '#56B282',
  },
  {
    key: 'e-retail-media',
    title: 'E-Retail Media',
    url: '/solutions#e-retail-media',
    tabs: [],
    tags: ['PAID AREAS', 'BANNER', 'CAMPAIGN PAGE'],
    description: [
      'Track and compare banner activities of your brand and competitors across retailers. Monitor paid media efforts, optimize ad spends using real-time data, and develop strategies that align with your target audience',
    ],
    Icon: ERetailMediaIcon,
    videoUrl: '/media/solutions/e-retail-media.mp4',
    videoPosterUrl: '/media/solutions/e-retail-media-poster.png',
    color: '#D22537',
  },
  {
    key: 'location-based-analytics',
    title: 'Location-Based Analytics',
    url: '/solutions/location-based-analytics',
    tabs: [],
    tags: ['STOCK', 'AVAILABILITY', 'LOCATION'],
    description: [
      'Monitor warehouse availability for last-mile delivery platforms. Keep an eye on inventory levels across various locations and proactively prevent revenue losses from stock-outs.',
    ],
    Icon: MultiLocationIcon,
    videoUrl: '/media/solutions/multi-location.mp4',
    videoPosterUrl: '/media/solutions/multi-location-poster.png',
    color: '#EF8C30',
  },
]

export default SOLUTIONS
