const MultiLocationIcon = ({ color = '#2563EB' }) => (
  <svg
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.2932 3.95508C10.469 3.95508 6.5918 8.01851 6.5918 12.9888C6.5918 14.5003 7.25754 15.9921 8.12437 17.3592C8.96644 18.6874 10.0368 19.9556 10.9753 21.0677C11.0062 21.1043 11.037 21.1408 11.0676 21.1771C11.6991 21.9256 12.4175 22.7914 13.0739 23.5912C14.1808 24.94 16.3476 24.8336 17.3926 23.4828C17.9857 22.716 18.6455 21.8965 19.2525 21.1771C19.4426 20.9517 19.6421 20.7189 19.8471 20.4797C20.6661 19.5237 21.5727 18.4657 22.3193 17.3737C23.2501 16.0125 23.9946 14.5141 23.9946 12.9888C23.9946 8.01851 20.1174 3.95508 15.2932 3.95508Z"
      stroke={color}
      strokeLinejoin="round"
    />
    <path
      d="M15.2947 15.4992C17.1716 15.4992 18.6597 13.9218 18.6597 12.0182C18.6597 10.1145 17.1716 8.53711 15.2947 8.53711C13.4177 8.53711 11.9297 10.1145 11.9297 12.0182C11.9297 13.9218 13.4177 15.4992 15.2947 15.4992Z"
      stroke={color}
    />
  </svg>
)

export default MultiLocationIcon
