const ERetailMediaIcon = ({ color = '#2563EB' }) => (
  <svg
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="4.66992"
      y="9.40088"
      width="21.23"
      height="11.1963"
      rx="4.5"
      stroke={color}
    />
    <path d="M4.54688 7.4624H26.0371" stroke={color} strokeLinecap="round" />
    <path d="M4.54688 22.5376H26.0371" stroke={color} strokeLinecap="round" />
  </svg>
)

export default ERetailMediaIcon
