import type { SVGProps } from 'react'

const ArrowDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.7652 7L10.8852 10.88L7.00523 7C6.91273 6.90729 6.80284 6.83373 6.68187 6.78354C6.56089 6.73335 6.43121 6.70752 6.30023 6.70752C6.16926 6.70752 6.03958 6.73335 5.9186 6.78354C5.79763 6.83373 5.68774 6.90729 5.59523 7C5.20523 7.39 5.20523 8.02 5.59523 8.41L10.1852 13C10.5752 13.39 11.2052 13.39 11.5952 13L16.1852 8.41C16.5752 8.02 16.5752 7.39 16.1852 7C15.7952 6.62 15.1552 6.61 14.7652 7Z"
      fill="currentColor"
    />
  </svg>
)

export default ArrowDownIcon
