import { HTMLAttributes } from 'react'

import Header from './header'

type Props = HTMLAttributes<HTMLDivElement>

const AppLayout = ({ children, className }: Props) => {
  return (
    <div className={className}>
      <Header />
      {children}
    </div>
  )
}

export default AppLayout
