const InstagramIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 56 57"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.1993 5.61914H37.7994C45.266 5.61914 51.3327 11.6858 51.3327 19.1525V38.7525C51.3327 42.3417 49.9069 45.784 47.3689 48.322C44.8309 50.86 41.3886 52.2858 37.7994 52.2858H18.1993C10.7327 52.2858 4.66602 46.2191 4.66602 38.7525V19.1525C4.66602 15.5632 6.09185 12.121 8.62984 9.58296C11.1678 7.04497 14.6101 5.61914 18.1993 5.61914ZM17.7327 10.2858C15.5049 10.2858 13.3683 11.1708 11.793 12.7461C10.2177 14.3214 9.33268 16.458 9.33268 18.6858V39.2191C9.33268 43.8625 13.0893 47.6191 17.7327 47.6191H38.266C40.4938 47.6191 42.6304 46.7341 44.2057 45.1588C45.781 43.5835 46.666 41.447 46.666 39.2191V18.6858C46.666 14.0425 42.9093 10.2858 38.266 10.2858H17.7327ZM40.2493 13.7858C41.0229 13.7858 41.7648 14.0931 42.3117 14.6401C42.8587 15.1871 43.166 15.9289 43.166 16.7025C43.166 17.476 42.8587 18.2179 42.3117 18.7649C41.7648 19.3118 41.0229 19.6191 40.2493 19.6191C39.4758 19.6191 38.7339 19.3118 38.187 18.7649C37.64 18.2179 37.3327 17.476 37.3327 16.7025C37.3327 15.9289 37.64 15.1871 38.187 14.6401C38.7339 14.0931 39.4758 13.7858 40.2493 13.7858ZM27.9993 17.2858C31.0935 17.2858 34.061 18.515 36.2489 20.7029C38.4369 22.8908 39.666 25.8583 39.666 28.9525C39.666 32.0467 38.4369 35.0141 36.2489 37.2021C34.061 39.39 31.0935 40.6191 27.9993 40.6191C24.9052 40.6191 21.9377 39.39 19.7498 37.2021C17.5618 35.0141 16.3327 32.0467 16.3327 28.9525C16.3327 25.8583 17.5618 22.8908 19.7498 20.7029C21.9377 18.515 24.9052 17.2858 27.9993 17.2858ZM27.9993 21.9525C26.1428 21.9525 24.3624 22.69 23.0496 24.0027C21.7368 25.3155 20.9993 27.096 20.9993 28.9525C20.9993 30.809 21.7368 32.5895 23.0496 33.9022C24.3624 35.215 26.1428 35.9525 27.9993 35.9525C29.8559 35.9525 31.6363 35.215 32.9491 33.9022C34.2619 32.5895 34.9993 30.809 34.9993 28.9525C34.9993 27.096 34.2619 25.3155 32.9491 24.0027C31.6363 22.69 29.8559 21.9525 27.9993 21.9525Z"
      fill="currentColor"
    />
  </svg>
)

export default InstagramIcon
