const ContentIcon = ({ color = '#2563EB' }) => (
  <svg
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0753 6.87061H4.29297C2.63611 6.87061 1.29297 8.21375 1.29297 9.87061V20.1294C1.29297 21.7862 2.63612 23.1294 4.29297 23.1294H10.0753C11.7321 23.1294 13.0753 21.7862 13.0753 20.1294V9.87061C13.0753 8.21375 11.7321 6.87061 10.0753 6.87061ZM4.29297 5.87061C2.08383 5.87061 0.292969 7.66147 0.292969 9.87061V20.1294C0.292969 22.3385 2.08383 24.1294 4.29297 24.1294H10.0753C12.2844 24.1294 14.0753 22.3385 14.0753 20.1294V9.87061C14.0753 7.66147 12.2844 5.87061 10.0753 5.87061H4.29297Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.2921 6.87061H20.5098C18.8529 6.87061 17.5098 8.21375 17.5098 9.87061V20.1294C17.5098 21.7862 18.8529 23.1294 20.5098 23.1294H26.2921C27.9489 23.1294 29.2921 21.7862 29.2921 20.1294V9.87061C29.2921 8.21375 27.9489 6.87061 26.2921 6.87061ZM20.5098 5.87061C18.3006 5.87061 16.5098 7.66147 16.5098 9.87061V20.1294C16.5098 22.3385 18.3006 24.1294 20.5098 24.1294H26.2921C28.5012 24.1294 30.2921 22.3385 30.2921 20.1294V9.87061C30.2921 7.66147 28.5012 5.87061 26.2921 5.87061H20.5098Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.168 13.312C19.168 13.0359 19.3918 12.812 19.668 12.812H27.1356C27.4117 12.812 27.6356 13.0359 27.6356 13.312C27.6356 13.5882 27.4117 13.812 27.1356 13.812H19.668C19.3918 13.812 19.168 13.5882 19.168 13.312Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.95117 13.312C2.95117 13.0359 3.17503 12.812 3.45117 12.812H10.9188C11.1949 12.812 11.4188 13.0359 11.4188 13.312C11.4188 13.5882 11.1949 13.812 10.9188 13.812H3.45117C3.17503 13.812 2.95117 13.5882 2.95117 13.312Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.168 16.688C19.168 16.4118 19.3918 16.188 19.668 16.188H27.1356C27.4117 16.188 27.6356 16.4118 27.6356 16.688C27.6356 16.9641 27.4117 17.188 27.1356 17.188H19.668C19.3918 17.188 19.168 16.9641 19.168 16.688Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.95117 16.688C2.95117 16.4118 3.17503 16.188 3.45117 16.188H10.9188C11.1949 16.188 11.4188 16.4118 11.4188 16.688C11.4188 16.9641 11.1949 17.188 10.9188 17.188H3.45117C3.17503 17.188 2.95117 16.9641 2.95117 16.688Z"
      fill={color}
    />
  </svg>
)

export default ContentIcon
