const Logo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="5.636363em"
      height="1em"
      viewBox="0 0 124 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_4368_122631)">
        <path
          d="M21.2334 21.7824H25.9274V12.9172C25.9274 8.83807 23.47 6.44499 19.6596 6.44499C17.423 6.44499 15.4902 7.66873 14.4686 9.27318C13.4193 7.45117 11.5417 6.44499 9.22232 6.44499C7.20666 6.44499 5.63279 7.28801 4.72161 8.51174V6.60816H0V21.7824H4.72161V13.5426C4.72161 11.5575 5.8813 10.4697 7.67606 10.4697C9.47083 10.4697 10.6305 11.5575 10.6305 13.5426V21.7824H15.3245V13.5426C15.3245 11.5575 16.4842 10.4697 18.279 10.4697C20.0737 10.4697 21.2334 11.5575 21.2334 13.5426V21.7824Z"
          fill="currentColor"
        />
        <path
          d="M29.2296 21.7824H33.9512V6.60816H29.2296V21.7824ZM31.6042 5.0309C33.2609 5.0309 34.393 3.91595 34.393 2.52905C34.393 1.11496 33.2609 0 31.6042 0C29.9199 0 28.7878 1.11496 28.7878 2.52905C28.7878 3.91595 29.9199 5.0309 31.6042 5.0309Z"
          fill="currentColor"
        />
        <path
          d="M48.0034 21.7824H52.6974V12.9172C52.6974 8.83807 50.3228 6.44499 46.7609 6.44499C44.69 6.44499 43.0333 7.3424 42.0945 8.62052V6.60816H37.3729V21.7824H42.0945V13.5426C42.0945 11.4759 43.2542 10.3337 45.049 10.3337C46.8437 10.3337 48.0034 11.4759 48.0034 13.5426V21.7824Z"
          fill="currentColor"
        />
        <path
          d="M55.0397 14.1681C55.0397 18.9543 58.0494 22 61.8322 22C64.0963 22 65.7254 20.9938 66.5814 19.6069V21.7824H71.303V1.65884H66.5814V8.7021C65.6426 7.3152 63.8754 6.39061 61.8322 6.39061C58.0494 6.39061 55.0397 9.38195 55.0397 14.1681ZM66.5814 14.1953C66.5814 16.6156 65.0075 17.9481 63.2128 17.9481C61.4456 17.9481 59.8441 16.5884 59.8441 14.1681C59.8441 11.7478 61.4456 10.4425 63.2128 10.4425C65.0075 10.4425 66.5814 11.775 66.5814 14.1953Z"
          fill="currentColor"
        />
        <path
          d="M87.227 17.377C87.1442 11.7206 78.8054 13.4339 78.8054 11.0136C78.8054 10.2794 79.4129 9.84425 80.5173 9.84425C81.8427 9.84425 82.6434 10.5241 82.7815 11.5575H87.1441C86.8404 8.56613 84.6315 6.39061 80.6554 6.39061C76.6241 6.39061 74.3875 8.51174 74.3875 11.0952C74.3875 16.67 82.7815 14.9023 82.7815 17.3498C82.7815 18.0297 82.1464 18.5735 80.9315 18.5735C79.6338 18.5735 78.6673 17.8665 78.5569 16.8059H73.8905C74.1114 19.7429 76.8726 22 80.9867 22C84.8524 22 87.227 20.0148 87.227 17.377Z"
          fill="currentColor"
        />
        <path
          d="M90.1157 21.7824H94.8373V6.60816H90.1157V21.7824ZM92.4903 5.0309C94.147 5.0309 95.279 3.91595 95.279 2.52905C95.279 1.11496 94.147 0 92.4903 0C90.8059 0 89.6739 1.11496 89.6739 2.52905C89.6739 3.91595 90.8059 5.0309 92.4903 5.0309Z"
          fill="currentColor"
        />
        <path
          d="M99.0045 16.5068C99.0045 20.4499 101.241 21.7824 104.444 21.7824H106.846V17.8393H105.162C104.113 17.8393 103.726 17.4586 103.726 16.5612V10.4697H106.819V6.60816H103.726V2.90977H99.0045V6.60816H97.1269V10.4697H99.0045V16.5068Z"
          fill="currentColor"
        />
        <path
          d="M116.269 10.1162C117.843 10.1162 119.168 11.068 119.168 12.7268H113.259C113.535 11.0408 114.722 10.1162 116.269 10.1162ZM123.641 16.7515H118.616C118.229 17.5674 117.484 18.22 116.158 18.22C114.64 18.22 113.397 17.2954 113.231 15.3103H123.917C123.972 14.848 124 14.3857 124 13.9506C124 9.32756 120.825 6.39061 116.324 6.39061C111.74 6.39061 108.537 9.38195 108.537 14.1953C108.537 19.0087 111.796 22 116.324 22C120.162 22 122.896 19.7157 123.641 16.7515Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_4368_122631">
          <rect width="124" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Logo
